import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import { Pagination, Navigation } from 'swiper'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function SliderMembers() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)

  const [swiper, setSwiper] = useState(null)

  useEffect(() => {
    fetch('/data/About/Members/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }

    handleResize()

    // 이벤트 리스너 추가
    window.addEventListener('resize', handleResize)

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const commonSwiperProps = {
    loop: true,
    onSwiper: (s) => {
      setSwiper(s)
    },
    modules: [Pagination, Navigation],
    pagination: true,
    centeredSlides: true,
  }

  return (
    <Container>
      <Header>
        <span>ABOUT US</span>
        <Title>Company Members</Title>
      </Header>
      {isDesktop ? (
        <StyledSwiper
          key="desktop"
          swiper={swiper}
          {...commonSwiperProps}
          slidesPerView={4}
          initialSlide={2}
          className="mySwiper"
        >
          {data.map((data) => {
            return (
              <SwiperSlide key={data.id}>
                <Img
                  onClick={() => {
                    navigate(`/about/members`)
                  }}
                  backgroundImg={data.img}
                />
                <Name className="name">{data.name_en}</Name>
                <Position className="position">{data.position}</Position>
              </SwiperSlide>
            )
          })}
        </StyledSwiper>
      ) : (
        <StyledSwiper
          key="mobile"
          swiper={swiper}
          {...commonSwiperProps}
          slidesPerView={2}
          initialSlide={1}
          className="mySwiper"
        >
          {data.map((data) => {
            return (
              <SwiperSlide key={data.id}>
                <Img
                  onClick={() => {
                    navigate(`/about/members`)
                  }}
                  backgroundImg={data.img}
                />
                <Name>{data.name_en}</Name>
                <Position>{data.position}</Position>
              </SwiperSlide>
            )
          })}
        </StyledSwiper>
      )}
      <Center>
        <Button
          type="button"
          onClick={() => {
            const win = window.open(`https://spresto.oopy.io/`, '_blank')
            win.focus()
          }}
        >
          {t('member_join')}
        </Button>
      </Center>
    </Container>
  )
}

export default SliderMembers

const Center = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 100%;
  padding-bottom: 140px;

  @media all and (max-width: 768px) {
    width: 100%;
    height: 46rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Header = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')};
  margin-top: 140px;
  margin-bottom: 100px;

  span {
    display: block;
    width: 155px;
    height: 43px;
    background-color: #edfcfd;
    border-radius: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
    letter-spacing: 0.3em;
    color: ${({ theme }) => theme.color.GPrimary};
  }
  @media all and (max-width: 768px) {
    margin-top: 5rem;
    margin-bottom: 40px;
  }
`

const Title = styled.h1`
  margin-top: 20px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  color: ${({ theme }) => theme.color.GPrimary};

  @media all and (max-width: 768px) {
    font-size: 1.9rem;
    text-align: center;
    margin-top: 0;
  }
`

const StyledSwiper = styled(Swiper)`
  transition: width 200ms ease-in-out;
  width: 100%;
  .swiper-wrapper {
    max-width: 1440px;
    width: 100%;
    height: 400px;
    align-items: center;
  }

  .swiper-slide {
    ${({ theme }) => theme.mixins.flexBox('column', 'center', 'center')};

    .name {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      letter-spacing: 0.1em;
      color: #666666;
    }

    .position {
      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.1em;
      color: #c4c4c4;
    }
    div {
      width: 180px;
      height: 180px;
      transition: width 200ms ease-in-out, height 200ms ease-in-out;
    }
  }

  .swiper-slide-active {
    .name {
      font-size: 24px;
      line-height: 35px;
    }
    .position {
      font-size: 18px;
      line-height: 26px;
    }
    div {
      width: 220px;
      height: 220px;
    }
  }
  .swiper-pagination {
    bottom: 0px;
    .swiper-pagination-bullet {
      background-color: #848282;
    }

    .swiper-pagination-bullet-active {
      background-color: ${({ theme }) => theme.color.GPrimary};
    }
  }
  @media all and (max-width: 768px) {
    .swiper-wrapper {
      width: 100%;
      height: 300px;
    }
    .swiper-slide {
      div {
        width: 140px;
        height: 140px;
      }
    }
    .swiper-slide-active {
      div {
        width: 160px;
        height: 160px;
      }
    }
  }
`

const Img = styled.div`
  width: 180px;
  height: 180px;
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 10px 20px rgba(0, 186, 199, 0.2);
  background-position: center center;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
`

const Name = styled.p`
  margin-top: 24px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #414141;
  @media all and (max-width: 768px) {
    margin-top: 0;
    width: 20rem;
    margin-top: 1.5rem;
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    color: #666666;
    text-align: center;
  }
`

const Position = styled.p`
  margin-top: 12px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #9f9f9f;

  @media all and (max-width: 768px) {
    width: 20rem;
    text-align: center;
    margin-top: 0;

    font-size: 1rem;
    color: #c4c4c4;
    letter-spacing: 0.1em;
    line-height: 1.2rem;
    font-weight: 500;
  }
`

const Button = styled.button`
  padding: 20px 50px;
  background: ${({ theme }) => theme.color.GPrimary};
  box-shadow: 0px 15px 30px rgba(0, 186, 199, 0.33);
  border: none;
  border-radius: 100px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: ${({ theme }) => theme.color.WPrimary};
  cursor: pointer;
  z-index: 2;
  @media all and (max-width: 768px) {
    position: static;
    width: 13rem;
    font-size: 1rem;
    padding: 0.7rem 1rem;
    box-shadow: 0px 5px 10px rgb(0 186 199 / 33%);
  }
`

//slider - pagenation_fraction
const SliderIndexBox = styled.div`
  position: relative;
  width: 115px;
  height: 18px;

  line-height: 18px;
  font-family: Montserrat;
  hr {
    ${({ theme }) => theme.mixins.positionCenterX('absolute')};
    width: 50px;
    border: 0.1px solid white;
    background-color: white;
  }
`
const SlideIndex = styled.p`
  display: none;
  display: flex;
  justify-content: space-between;
  width: 115px;
  color: transparent;
  text-align: center;
  span {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: ${(props) => props.theme.color.WPrimary};
  }
  span::before {
    content: '0';
  }

  @media all and (max-width: 768px) {
    display: block;
    span::before {
      content: '';
    }
  }
`
